import React from "react"
import "../styles/styles.scss"
import ALSPageComponent from "../components/alsPageComponent"
//import LsPageComponent from "../components/lsPageComponent"
import { Helmet } from "react-helmet"

const LSPage = () => (
  
  <div>
  <Helmet
    title="Security-Alarm-KMU" defer={false}>
      <html lang="de" />
      <meta name="description" content="ALARM-Projekt -- Analoge Lernszenarien" />
      <meta name="keywords" content="Game, Security, ALARM, Awareness, KMU" />
  </Helmet>
    <ALSPageComponent
      ls="3" 
      game="als3"
      motto="CEO Fraud"
      moderation="Als03OModeration"
      handout="Als03OHandout"
      konstruktion="Als03OKonstruktion"
      vorlage="Als03ODruckvorlagen"/>
  </div>
)

export default LSPage
